import { useEffect, useState } from 'react';

export function useAuth() {
  const [loggedUser, setLoggedUser] = useState(() => {
  const localStorageLoggedUserInfo = localStorage.getItem('loggedUserInfo');

  if(localStorageLoggedUserInfo){
     
      return JSON.parse(localStorageLoggedUserInfo);
    }else{
      return null;
    }
    
  });
  useEffect(() => {
    localStorage.setItem('loggedUserInfo', JSON.stringify(loggedUser));
  }, [loggedUser]);

  return { loggedUser, setLoggedUser };
}

import { useState, useEffect, createContext, Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
//import dotenv from 'dotenv';
import { ThemeProvider } from '@mui/material/styles';

import GlobalStyles from 'src/components/auxiliares/GlobalStyles';
import theme from 'src/theme';
import { getMethod } from 'src/utils/api';

import { DashBoard } from 'src/pages/DashBoard';
import NotFound from 'src/pages/NotFound';
import GoLogin from 'src/pages/GoLogin';
import { useAuth } from 'src/utils/useAuth';

export const SocietyContext = createContext({});
export const EstadosContext = createContext({});
export const EstadosPedidoContext = createContext({});
export const EstadosPagoContext = createContext({});
export const FormaCobrosContext = createContext({});
export const FormaPagosContext = createContext({});
export const FormaPagosFiduContext = createContext({});
export const RetencionesContext = createContext({});
export const FondosContext = createContext({});
export const TiposContext = createContext({});
export const CondicionIVAContext = createContext({});
export const ConceptosCuotaContext = createContext({});
export const ConceptosPagoContext = createContext({});
export const ConceptosDepoContext = createContext({});
export const TipoProductosContext = createContext({});
export const LetrasContext = createContext({});

const Liquidacion = lazy(() => import('src/pages/Liquidacion'));
const Contrato = lazy(() => import('src/pages/Contrato'));
const DetalleContrato = lazy(() => import('src/pages/DetalleContrato'));
const Cobros = lazy(() => import('src/pages/Cobros'));
const Cuotas = lazy(() => import('src/pages/Cuotas'));
const Pedido = lazy(() => import('src/pages/Pedido'));
const DetallePedido = lazy(() => import('src/pages/DetallePedido'));
const OC = lazy(() => import('src/pages/OC'));
const DetalleOC = lazy(() => import('src/pages/DetalleOC'));
const OT = lazy(() => import('src/pages/OT'));
const DetalleOT = lazy(() => import('src/pages/DetalleOT'));
const BP = lazy(() => import('src/pages/BP'));
const Cotizacion = lazy(() => import('src/pages/Cotizacion'));   // OBRA
const Presupuesto = lazy(() => import('src/pages/Presupuesto')); // OBRA x periodo
const DetalleBP = lazy(() => import('src/pages/DetalleBP'));
const DetalleObra = lazy(() => import('src/pages/DetalleObra')); // cotizacion - OBRA
const DetallePresupuesto = lazy(() => import('src/pages/DetallePresupuesto')); // presupuesto - OBRA x periodo
const Proveedores = lazy(() => import('src/pages/Proveedores'));
const Fideicomiso = lazy(() => import('src/pages/Fideicomiso'));
const DetalleFideicomiso = lazy(() => import('src/pages/DetalleFideicomiso'));
const Fiduciantes = lazy(() => import('src/pages/Fiduciantes'));
const Banco = lazy(() => import('src/pages/Banco'));
const CuentaBanco = lazy(() => import('src/pages/CuentaBanco'));
const Cuentas = lazy(() => import('src/pages/Cuentas'));
const Movimientos = lazy(() => import('src/pages/Movimientos'));
const DetalleMovimientos = lazy(() => import('src/pages/DetalleMovimientos'));
const Cajas = lazy(() => import('src/pages/Cajas'));
const Dolar = lazy(() => import('src/pages/Dolar'));
const Cac = lazy(() => import('src/pages/Cac'));
const Rubro = lazy(() => import('src/pages/Rubro'));
const SubRubro = lazy(() => import('src/pages/SubRubro'));
const Categoria = lazy(() => import('src/pages/Categoria'));
const Usuarios = lazy(() => import('src/pages/Usuarios'));
const DashboardLayout = lazy(() => import('src/components/auxiliares/DashboardLayout'));
const MainLayout = lazy(() => import('src/components/auxiliares/MainLayout'));
const Login = lazy(() => import('src/pages/Login'));
const OP = lazy(() => import('src/pages/OP'));
const AuthAdmOC = lazy(() => import('src/pages/AuthAdmOC'));
const AuthAdmOP = lazy(() => import('src/pages/AuthAdmOP'));
const AuthObraOP = lazy(() => import('src/pages/AuthObraOP'));
const DetalleOP = lazy(() => import('src/pages/DetalleOP'));
const Factura = lazy(() => import('src/pages/Factura'));

// dotenv.config();

export default function App() { 

const [idSociety, setIdSociety] = useState(() => {
  const localStorageIdSociety = localStorage.getItem('idSociety');
  return localStorageIdSociety ? JSON.parse(localStorageIdSociety) : null;
});

const { loggedUser, setLoggedUser } = useAuth();

const [estados, setEstados] = useState([]);
const [estadosPedido, setEstadosPedido] = useState([]);
const [estadosPago, setEstadosPago] = useState([]);
const [formaPagos, setFormaPagos] = useState([]);
const [formaPagosFidu, setFormaPagosFidu] = useState([]);  
const [retenciones, setRetenciones] = useState([]);
const [fondos, setFondos] = useState([]);  
const [tipos, setTipos] = useState([]);
const [condicion_de_IVA, setCondicion_de_IVA] = useState([]);  
const [tipoProductos, setTipoProductos] = useState([]);
const [letras, setLetras] = useState([]); 
const [conceptosCuota, setConceptosCuota] = useState([]); 
const [conceptosPago, setConceptosPago] = useState([]); 
const [conceptosDepo, setConceptosDepo] = useState([]); 

useEffect(
  (idSociety)=> {

    getMethod(`listas/conjunto`).then((dt) => {
      setEstados(dt.estados);
      setEstadosPedido(dt.estadosPedido);
      setEstadosPago(dt.estadosPago);
      setFormaPagos(dt.formaPagos);
      setFormaPagosFidu(dt.formaPagosFidu);
      setRetenciones(dt.retenciones);
      setFondos(dt.fondos);
      setTipos(dt.tipos);
      setCondicion_de_IVA(dt.condicion_de_IVA);
      setTipoProductos(dt.tipoProductos);
      setLetras(dt.letras);
      setConceptosCuota(dt.conceptosCuota);
      setConceptosPago(dt.conceptosPago);
      setConceptosDepo(dt.conceptosDepo);
          
          });
    

  },[])

  return (

    <SocietyContext.Provider value={idSociety}>
    <EstadosContext.Provider value={estados}>
    <EstadosPedidoContext.Provider value={estadosPedido}> 
    <EstadosPagoContext.Provider value={estadosPago}> 
    <FormaPagosFiduContext.Provider value={formaPagosFidu} >
    <FormaPagosContext.Provider value={formaPagos} >
    <RetencionesContext.Provider value={retenciones}>
    <FondosContext.Provider value={fondos}>
    <TiposContext.Provider value={tipos}>
    <CondicionIVAContext.Provider value={condicion_de_IVA}>
    <ConceptosCuotaContext.Provider value={conceptosCuota}>
    <ConceptosPagoContext.Provider value={conceptosPago}>
    <ConceptosDepoContext.Provider value={conceptosDepo}>
    <TipoProductosContext.Provider value={tipoProductos}>
    <LetrasContext.Provider value={letras}>    
    

    <ThemeProvider theme={theme}>
      <GlobalStyles />
      
      <Routes>
        {loggedUser ? (
          <>

            <Route path="/" element={<Navigate to={`${idSociety?.nombre}`} />} />
            <Route
              path=":societyName"
              element={
                <Suspense fallback={<h6> loading .. </h6>} ><DashboardLayout                       
                  setLoggedUser={setLoggedUser}
                  idSociety={idSociety}
                  loggedUser={loggedUser}                  
                /></Suspense>
              }
            >             
              
              <Route path="dashboard" element={<DashBoard idSociety={idSociety} loggedUser={loggedUser}  />} />
                   
              <Route path="BP">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><BP idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":fideicomisoId/:modo/:monedaOBRA/:CACini/:CACact/:nombre/:ARS/:USD/:EUR/:title"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleBP idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>
              <Route path="cotizacion">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Cotizacion idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":fideicomisoId/:modo/:monedaOBRA/:CACini/:CACact/:nombre/:ARS/:USD/:EUR/:title"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleObra idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>
              <Route path="presupuesto">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Presupuesto idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":fideicomisoId/:modo/:monedaOBRA/:CACini/:CACact/:nombre/:ARS/:USD/:EUR/:title"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetallePresupuesto idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="proveedores" element={<Suspense fallback={<h6> loading .. </h6>} ><Proveedores idSociety={idSociety}   loggedUser={loggedUser} /></Suspense>} />
              <Route path="fiduciantes" element={<Suspense fallback={<h6> loading .. </h6>} ><Fiduciantes estilo={"fiduciante"} idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />
              <Route path="clientes" element={<Suspense fallback={<h6> loading .. </h6>} ><Fiduciantes estilo={"cliente"} idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />

              <Route path="fideicomiso">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Fideicomiso idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />
                <Route
                  path=":modo/:fideicomisoId/:nombre"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleFideicomiso idSociety={idSociety}   loggedUser={loggedUser}  /></Suspense>}
                />
              </Route>

              <Route path="banco"> 
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Banco idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":idBanco/:banco"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><CuentaBanco tipo={'cu_banco'}  idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>
              <Route path="cuentas"> 
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Cuentas idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":moneda/:cuenta/:idCuenta/:fide/:banco/:tipo/:flagSaldo/:pagina"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleMovimientos idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>
              <Route path="movimientos"> 
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Movimientos idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":moneda/:cuenta/:idCuenta/:fide/:banco/:tipo/:flagSaldo/:pagina"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleMovimientos idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>             
              
              <Route path="cajas" element={<Suspense fallback={<h6> loading .. </h6>} ><Cajas idSociety={idSociety} loggedUser={loggedUser}  /></Suspense>} />
              <Route path="dolar" element={<Suspense fallback={<h6> loading .. </h6>} ><Dolar idSociety={idSociety} loggedUser={loggedUser}  /></Suspense>} />
              <Route path="cac" element={<Suspense fallback={<h6> loading .. </h6>} ><Cac idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />
                    
             <Route path="rubro"> 
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Rubro idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":idRubro/:rubro"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><SubRubro idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>
              <Route path="categoria" element={<Suspense fallback={<h6> loading .. </h6>} ><Categoria idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />
              <Route path="usuarios" element={<Suspense fallback={<h6> loading .. </h6>} ><Usuarios idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />

              <Route path="contrato">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Contrato idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":contratoId/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleContrato idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="liquidacion" element={<Suspense fallback={<h6> loading .. </h6>} ><Liquidacion idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />
              <Route path="cobros" element={<Suspense fallback={<h6> loading .. </h6>} ><Cobros idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />
              <Route path="cuotas" element={<Suspense fallback={<h6> loading .. </h6>} ><Cuotas idSociety={idSociety}  loggedUser={loggedUser}  /></Suspense>} />
              
              <Route path="pedido">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Pedido idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":pedidoId/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetallePedido idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="OC">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><OC idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":idOC/:empresaId/:esNotaPedido/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOC idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="nota_de_pedido">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><OC idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":idOC/:empresaId/:esNotaPedido/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOC idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="OT">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><OT idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":idOT/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOT idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>            

              <Route path="AuthAdmOC">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><AuthAdmOC idSociety={idSociety}  formaPagos={formaPagos}  loggedUser={loggedUser} /></Suspense>}  />
                <Route
                    path=":idOC/:esNotaPedido/:page"
                    element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOC idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="clienteFactura">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Factura idSociety={idSociety} loggedUser={loggedUser} ingreso={1} /></Suspense>} />
                <Route
                  path=":idOP/:fecha/:empresaId/:numero/:fideicomisoId/:fideicomiso/:estadoOP/:auth_adm/:auth_obra/:confirmada/:blue/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOP idSociety={idSociety} formaPagos={formaPagos} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>
              
              <Route path="factura">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><Factura idSociety={idSociety} loggedUser={loggedUser} ingreso={0} /></Suspense>} />
                <Route
                  path=":idOP/:fecha/:empresaId/:numero/:fideicomisoId/:fideicomiso/:estadoOP/:auth_adm/:auth_obra/:confirmada/:blue/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOP idSociety={idSociety} formaPagos={formaPagos} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="ingreso">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><OP idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":idOP/:fecha/:empresaId/:numero/:fideicomisoId/:fideicomiso/:estadoOP/:auth_adm/:auth_obra/:confirmada/:blue/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOP idSociety={idSociety} formaPagos={formaPagos}  loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="OP">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><OP idSociety={idSociety} loggedUser={loggedUser} /></Suspense>} />
                <Route
                  path=":idOP/:fecha/:empresaId/:numero/:fideicomisoId/:fideicomiso/:estadoOP/:auth_adm/:auth_obra/:confirmada/:blue/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOP idSociety={idSociety} formaPagos={formaPagos}  loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="AuthAdmOP">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><AuthAdmOP idSociety={idSociety}  formaPagos={formaPagos}  loggedUser={loggedUser} /></Suspense>}  />
                <Route
                  path=":idOP/:fecha/:empresaId/:numero/:fideicomisoId/:fideicomiso/:estadoOP/:auth_adm/:auth_obra/:confirmada/:blue/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOP idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>
              <Route path="AuthObraOP">
                <Route path="" element={<Suspense fallback={<h6> loading .. </h6>} ><AuthObraOP idSociety={idSociety} formaPagos={formaPagos}   loggedUser={loggedUser} /></Suspense>}  />
                <Route
                  path=":idOP/:fecha/:empresaId/:numero/:fideicomisoId/:fideicomiso/:estadoOP/:auth_adm/:auth_obra/:confirmada/:blue/:page"
                  element={<Suspense fallback={<h6> loading .. </h6>} ><DetalleOP idSociety={idSociety} loggedUser={loggedUser} /></Suspense>}
                />
              </Route>

              <Route path="login" element={<GoLogin  idSociety={idSociety} loggedUser={loggedUser}   />} />
              <Route path="*" element={<NotFound />} /> 

              
            </Route>
  
          </>
        ) : (
          
            <MainLayout>
              <Route
                path=":societyName/login"
                element={<Suspense fallback={<h6> loading .. </h6>} >
                  <Login
                    setLoggedUser={setLoggedUser}
                    setIdSociety={setIdSociety}
                  /></Suspense>
                }
              />
            
                <Route path=":societyName" element={<Navigate to="login" />} />
                <Route path="*" element={<Navigate to="/" />} />

            </MainLayout>
        )}
      </Routes>

    </ThemeProvider>
                
    </LetrasContext.Provider>
    </TipoProductosContext.Provider>
    </ConceptosDepoContext.Provider>
    </ConceptosPagoContext.Provider>
    </ConceptosCuotaContext.Provider>
    </CondicionIVAContext.Provider>
    </TiposContext.Provider>
    </FondosContext.Provider>    
    </RetencionesContext.Provider>
    </FormaPagosContext.Provider>
    </FormaPagosFiduContext.Provider>
    </EstadosPagoContext.Provider>
    </EstadosPedidoContext.Provider>
    </EstadosContext.Provider>
    </SocietyContext.Provider>
    
  )}
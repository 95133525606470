import { Helmet } from 'react-helmet';
import { Container, Box, Typography } from '@mui/material';


const NotFound = () => (
  <>
    <Helmet>
      <title>404</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container >
        <Typography align="center" color="textPrimary" variant="h1">
          404: La página no existe
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <img
            alt="Under development"
            src="/static/images/undraw_page_not_found_su7k.svg"
            style={{
              marginTop: 50,
              display: 'inline-block',
              width: 560,
            }}
          />
        </Box>
      </Container>
    </Box>
  </>
);

export default NotFound;

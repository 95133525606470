import { Helmet } from 'react-helmet';
import { Container, Box, Typography } from '@mui/material';
// import { useContext } from 'react';
// import { SocietyContext } from 'src/App';
import { useNavigate } from 'react-router-dom';
// import { DashboardNavbar } from 'src/components/auxiliares/DashboardNavbar.js';
// import DashboardNavbar from 'src/components/auxiliares/DashboardNavbar';

export default function GoLogin({loggedUser}) {

  // const idSociety = useContext(SocietyContext);
  const navigate = useNavigate();

  var ir = 0;
  if(!loggedUser){
    // logOut
    console.log("deuda tecnica: !loggedUser or Token vencido, deberia redireccionar al login");
     
  }else{
    ir = 1;
    
    setTimeout(() => {
      navigate(`../DashBoard`);  
    }, 2500)
    
  }
  
  return (
  <>
    <Helmet>
      <title>goLogin</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container >
        <Typography align="center" color="textPrimary" variant="h1">
          {ir ===0? 'La página redirecciona al login':'... redirecciona a la página Dashboard'}
        </Typography>
        
      </Container>
    </Box>
  </>
);
}